import React, { useState } from "react";
import ReactGA from "react-ga4";
import { BELLEVUE_MENU_ITEMS } from "../data/BellevueMenu";
import { UDISTRICT_MENU_ITEMS } from "../data/UDistrictMenu";
import { WESTLAKE_MENU_ITEMS } from "../data/WestlakeMenu";
import CaretDown from "../images/caret-down-dark.svg";

interface MenuHeaderProps {
  location: string;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
}

export default function MenuHeader(props: MenuHeaderProps) {
  const { location, setLocation } = props;

  const [toggleOpen, setToggleOpen] = useState(false);

  function handleClickLocation() {
    ReactGA.event("select_content", {
      content_type: "menu location toggle",
    });
    setToggleOpen(!toggleOpen);
  }

  function handleClickLocationDropdown(label: string) {
    ReactGA.event("select_content", {
      content_type: "menu location dropdown",
      restaurant_location: label,
    });
    setLocation(label);
    setToggleOpen(false);
  }

  function handleClickMenuHeader(header: string) {
    ReactGA.event("select_content", {
      content_type: "menu header",
      restaurant_location: location,
      value: header.toLowerCase(),
    });
  }

  function renderOption(label: string): JSX.Element {
    return (
      <button
        className="mx-2 px-4 py-1 bg-primary text-lightText hover:brightness-90 duration-300"
        onClick={() => {
          handleClickLocationDropdown(label);
        }}
      >
        {label.toUpperCase()}
      </button>
    );
  }

  function renderLocationSelect(): JSX.Element {
    return (
      <div onMouseLeave={() => setToggleOpen(false)}>
        <button
          className="mx-2 px-4 py-1 text-darkText duration-300 border-primary border"
          onClick={handleClickLocation}
          onMouseEnter={() => setToggleOpen(true)}
        >
          <span className="flex items-center justify-between">
            <p className="mr-2">{location.toUpperCase()}</p>
            <img
              className={`h-1.5 duration-300 ${toggleOpen && "rotate-180"}`}
              src={CaretDown}
              alt="select arrow"
            />
          </span>
        </button>
        <div
          className={`absolute flex flex-col divide-y duration-300 ${
            toggleOpen ? "opacity-100" : "invisible opacity-0"
          }`}
        >
          {renderOption("udistrict")}
          {renderOption("bellevue")}
          {renderOption("westlake")}
        </div>
      </div>
    );
  }

  function renderMenuHeaders() {
    const result: JSX.Element[] = [];

    var menu;
    switch (location) {
      case "udistrict":
        menu = UDISTRICT_MENU_ITEMS;
        break;
      case "bellevue":
        menu = BELLEVUE_MENU_ITEMS;
        break;
      case "westlake":
        menu = WESTLAKE_MENU_ITEMS;
        break;
      default:
        menu = UDISTRICT_MENU_ITEMS;
    }

    Object.keys(menu).forEach((header) => {
      result.push(
        <a
          href={`#${header.toLowerCase().replaceAll(" ", "-")}`}
          key={header}
          onClick={() => {
            handleClickMenuHeader(header);
          }}
        >
          <button className="text-darkText mr-2 mb-2 py-1 px-2 border rounded border-primary hover:bg-primary hover:text-lightText duration-300">
            {header}
          </button>
        </a>
      );
    });
    return result;
  }

  return (
    <div id="menu" className="mx-6 md:mx-8 py-4 ">
      <div className="flex items-center mb-4">
        <h2 className="mr-4 text-primary font-bold text-2xl">MENU</h2>
        {renderLocationSelect()}
      </div>
      {renderMenuHeaders()}
    </div>
  );
}
