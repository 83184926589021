import Pepper from "../images/pepper.svg";
import { LocationMenuItemModel } from "../models/LocationMenuItemModel";
import { useMemo, useState } from "react";
import ImageModal from "./ImageModal";

interface MenuItemProps {
  locationMenuItem: LocationMenuItemModel;
}

export default function MenuItem(props: MenuItemProps) {
  const { menuItem, price } = props.locationMenuItem;
  const { id, title, subtitle, spicy, description } = menuItem;

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const imageExists = useMemo(() => {
    try {
      require(`../images/menu/${id}.jpg`);
      require(`../images/menu/thumbnails/${id}.jpg`);
      return true;
    } catch {
      return false;
    }
  }, [id]);

  function renderThumbnail() {
    if (!imageExists) return null;

    return (
      <img
        className="shrink-0 mr-2 w-12 object-cover rounded-md drop-shadow-md duration-300 hover:scale-105 hover:cursor-pointer"
        src={require(`../images/menu/thumbnails/${id}.jpg`)}
        alt={title}
      />
    );
  }

  return (
    <>
      <div
        className="flex mx-6 md:mx-8 mb-4 text-darkText md:w-1/2 sm:text-lg items-center"
        onClick={imageExists ? () => setImageModalOpen(true) : undefined}
      >
        {renderThumbnail()}
        <div className="flex flex-grow justify-between">
          <div>
            {title}
            {spicy && (
              <img
                className="ml-1 mb-1 w-4 inline"
                src={Pepper}
                alt="spicy pepper"
              />
            )}
            <div className="text-primary -mt-1">{subtitle}</div>
            {description && (
              <div className="text-slate-600 text-sm">{description}</div>
            )}
          </div>
          <div className="ml-4">{price.toFixed(2)}</div>
        </div>
      </div>
      {imageExists && imageModalOpen && (
        <ImageModal
          imageUrl={require(`../images/menu/${id}.jpg`)}
          setOpen={setImageModalOpen}
        />
      )}
    </>
  );
}
