import React from "react";
import FooterInfoBlock from "../components/FooterInfoBlock";

export default function FooterSection() {
  return (
    <div className="py-4 px-4 bg-primary md:flex justify-between justify-center md:items-end">
      <div className="md:flex py-4">
        <FooterInfoBlock header="Media Contact" info={["media@xiannoodles.com", "206-522-8888"]} />
      </div>
      <p className="font-bold text-sm text-center">XI'AN NOODLES | 2023</p>
    </div>
  );
}
