import FeaturedInSection from "../sections/FeaturedInSection";
import HeroSection from "../sections/HeroSection";
import LocationSection from "../sections/LocationSection";
import MenuSection from "../sections/MenuSection";
import SubheaderSection from "../sections/SubheaderSection";

export default function HomePage() {
  return (
    <>
      <HeroSection />
      <SubheaderSection />
      <FeaturedInSection />
      <LocationSection />
      <MenuSection />
    </>
  );
}
