import { ImageListItem } from "@mui/material";
import { useState } from "react";
import ImageModal from "./ImageModal";

interface GalleryImageProps {
  image: string;
}

export default function GalleryImage({ image }: GalleryImageProps) {
  const [imageModalOpen, setImageModalOpen] = useState(false);

  return (
    <ImageListItem key={image}>
      <img
        className="aspect-square object-cover hover:cursor-pointer hover:brightness-75 duration-300"
        src={image}
        alt={"Xi'an Noodles team"}
        loading="lazy"
        onClick={() => setImageModalOpen(true)}
      />
      {imageModalOpen && (
        <ImageModal imageUrl={image} setOpen={setImageModalOpen} />
      )}
    </ImageListItem>
  );
}
