import React from "react";
import CloseIcon from "../images/close.svg";

interface ImageModalProps {
  imageUrl: string;
  setOpen: (open: boolean) => void;
}

export default function ImageModal(props: ImageModalProps) {
  const { imageUrl, setOpen } = props;

  document.body.style.overflow = "hidden";

  function handleClose() {
    document.body.style.overflow = "unset";
    setOpen(false);
  }

  return (
    <div className="fixed top-0 left-0 h-screen w-screen bg-gray-900/75 z-10">
      <div className="flex flex-col h-full w-full">
        <div className="flex h-12 justify-end items-center">
          <img
            className="w-4 h-4 mr-4 hover:cursor-pointer"
            src={CloseIcon}
            onClick={handleClose}
            alt="Close icon"
          />
        </div>
        <div
          style={{ backgroundImage: `url('${imageUrl}')` }}
          className={`flex-grow mb-12 mx-4 bg-contain bg-no-repeat bg-center`}
          onClick={handleClose}
        />
      </div>
    </div>
  );
}
