import React from "react";

interface FooterInfoProps {
  header: string;
  info: string[];
}

export default function FooterInfoBlock(props: FooterInfoProps) {
  const { header, info } = props;

  function renderInfo() {
    return info.map((info, idx) => {
      return (
        <h4 className={idx !== info.length - 1 ? `-mb-1` : ""} key={info}>
          {info}
        </h4>
      );
    });
  }

  return (
    <div className="mx-2 md:mx-4 mb-4 md:mb-0">
      <div className="font-bold">{header.toLocaleUpperCase()}</div>
      {renderInfo()}
    </div>
  );
}
