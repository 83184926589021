import { MenuItemModel } from "../models/MenuItemModel";

// ------------------ NOODLES WITHOUT SOUP ------------------

export const CucumberSalad: MenuItemModel = {
  id: "cucumber-salad",
  title: "Cucumber Salad w/ Garlic Sauce",
  subtitle: "凉拌小黄瓜",
  spicy: true,
};

export const BroccoliGarlicSauce: MenuItemModel = {
  id: "broccoli-garlic-sauce",
  title: "Broccoli w/ Garlic Sauce",
  subtitle: "蒜蓉西蓝花",
  spicy: true,
};

export const PotatoSalad: MenuItemModel = {
  id: "potato-salad",
  title: "Shredded Potato Salad",
  subtitle: "炝炒土豆丝",
  spicy: true,
};

export const BeanSprouts: MenuItemModel = {
  id: "bean-sprouts",
  title: "Bean Sprouts w/ Gluten",
  subtitle: "豆芽面筋",
};

export const WoodEarMushrooms: MenuItemModel = {
  id: "wood-ear-mushrooms",
  title: "Wood Ear Mushrooms",
  subtitle: "椒麻小木耳",
  spicy: true,
};

export const Edamame: MenuItemModel = {
  id: "edamame",
  title: "Edamame",
  subtitle: "盐水毛豆",
};

export const LotusRoot: MenuItemModel = {
  id: "lotus-root",
  title: "Hot & Sour Lotus Root",
  subtitle: "酸辣藕片",
  spicy: true,
};

export const HandPulledLamb: MenuItemModel = {
  id: "hand-pulled-lamb",
  title: "Hand Pulled Lamb",
  subtitle: "手撕羊肉",
};

export const MarinatedPorkIntestine: MenuItemModel = {
  id: "marinated-pork-intestine",
  title: "Marinated Pork Intestine",
  subtitle: "卤水大肠",
  spicy: true,
};

export const SichuanSlicedBeef: MenuItemModel = {
  id: "sichuan-sliced-beef",
  title: "Sichuan Sliced Beef in Chili Oil",
  subtitle: "夫妻肺片",
  spicy: true,
};

export const MouthwateringChickenChiliOil: MenuItemModel = {
  id: "mouthwatering-chicken-chili-oil",
  title: "Mouth-Watering Chicken in Chili Oil",
  subtitle: "口水鸡",
  spicy: true,
};

// ------------------ NOODLES WITHOUT SOUP ------------------

export const LambNoodles: MenuItemModel = {
  id: "lamb-noodles",
  title: "Spicy Cumin Lamb Biang Noodles",
  subtitle: "孜然羊肉拌面",
  spicy: true,
};

export const BeefNoodles: MenuItemModel = {
  id: "beef-noodles",
  title: "Spicy & Tingly Beef Biang Noodles",
  subtitle: "麻辣牛肉拌面",
  spicy: true,
};

export const BraisedPorkNoodles: MenuItemModel = {
  id: "braised-pork-noodles",
  title: "Braised Pork Biang Noodles",
  subtitle: "腊汁猪肉拌面",
};

export const HotOilNoodles: MenuItemModel = {
  id: "hot-oil-noodles",
  title: "Hot Oil Biang Noodles",
  subtitle: "油泼面",
  spicy: true,
};

export const TomatoEggNoodles: MenuItemModel = {
  id: "tomato-egg-noodles",
  title: "Stir Fried Tomato & Egg Biang Noodles",
  subtitle: "番茄鸡蛋面",
};

export const VegetableNoodles: MenuItemModel = {
  id: "vegetable-noodles",
  title: "Vegetable Biang Noodles",
  subtitle: "蔬菜拌面",
};

export const QishanPorkNoodles: MenuItemModel = {
  id: "qishan-pork-noodles",
  title: "Qishan Pork Biang Noodles",
  subtitle: "岐山哨子拌面",
  spicy: true,
};

export const MouthwateringChickenNoodles: MenuItemModel = {
  id: "mouthwatering-chicken-noodles",
  title: "Mouth-Watering Chicken Biang Noodles",
  subtitle: "口水鸡面",
  spicy: true,
};

// ------------------ NOODLE SOUP ------------------

export const LambNoodleSoup: MenuItemModel = {
  id: "lamb-noodle-soup",
  title: "Spicy Cumin Lamb Biang Noodle Soup",
  subtitle: "孜然羊肉汤面",
  spicy: true,
};

export const BeefNoodleSoup: MenuItemModel = {
  id: "beef-noodle-soup",
  title: "Spicy & Tingly Beef Biang Noodle Soup",
  subtitle: "麻辣牛肉汤面",
  spicy: true,
};

export const BraisedPorkNoodleSoup: MenuItemModel = {
  id: "braised-pork-noodle-soup",
  title: "Braised Pork Biang Noodle Soup",
  subtitle: "腊汁猪肉汤面",
};

export const VegetableNoodleSoup: MenuItemModel = {
  id: "vegetable-noodle-soup",
  title: "Vegetable Biang Noodle Soup",
  subtitle: "蔬菜汤面",
};

export const QishanPorkNoodleSoup: MenuItemModel = {
  id: "qishan-pork-noodle-soup",
  title: "Qishan Pork Biang Noodle Soup",
  subtitle: "岐山哨子汤面",
  spicy: true,
};

// ------------------ RICE NOODLE SOUP ------------------

export const LambRiceNoodleSoup: MenuItemModel = {
  id: "lamb-rice-noodle-soup",
  title: "Spicy Cumin Lamb Rice Noodle Soup",
  subtitle: "孜然羊肉米线",
  spicy: true,
};

export const BeefRiceNoodleSoup: MenuItemModel = {
  id: "beef-rice-noodle-soup",
  title: "Spicy & Tingly Beef Rice Noodle Soup",
  subtitle: "麻辣牛肉米线",
  spicy: true,
};

export const PorkIntestineRiceNoodleSoup: MenuItemModel = {
  id: "pork-intestine-rice-noodle-soup",
  title: "Pork Intestine Rice Noodle Soup",
  subtitle: "大肠米线",
};

export const BoneBrothRiceNoodleSoup: MenuItemModel = {
  id: "bone-broth-rice-noodle-soup",
  title: "Bone Broth Rice Noodle Soup",
  subtitle: "骨汤米线",
};

export const SpicyTinglyRiceNoodleSoup: MenuItemModel = {
  id: "spicy-tingly-rice-noodle-soup",
  title: "Spicy & Tingly Rice Noodle Soup",
  subtitle: "麻辣米线",
  spicy: true,
};

export const HotSourRiceNoodleSoup: MenuItemModel = {
  id: "hot-sour-rice-noodle-soup",
  title: "Hot & Sour Rice Noodle Soup",
  subtitle: "酸辣米线",
  spicy: true,
};

export const MushroomVegetableRiceNoodleSoup: MenuItemModel = {
  id: "mushroom-vegetable-rice-noodle-soup",
  title: "Mushroom & Vegetable Rice Noodle Soup",
  subtitle: "菌菇蔬菜米线",
};

// ------------------ STREET FOOD ------------------

export const LambBun: MenuItemModel = {
  id: "lamb-bun",
  title: "Spicy Cumin Lamb Bun",
  subtitle: "孜然羊肉夹馍",
  spicy: true,
};

export const BeefBun: MenuItemModel = {
  id: "beef-bun",
  title: "Cumin Beef Bun",
  subtitle: "孜然牛肉夹馍",
  spicy: true,
};

export const PorkBun: MenuItemModel = {
  id: "pork-bun",
  title: "Pork Bun",
  subtitle: "腊汁猪肉夹馍",
};

export const PorkWontonsChiliSauce: MenuItemModel = {
  id: "pork-wontons-chili-sauce",
  title: "Pork Wontons in Chili Sauce",
  subtitle: "红油抄手",
  spicy: true,
};

export const PorkWontonsChickenSoup: MenuItemModel = {
  id: "pork-wontons-chicken-soup",
  title: "Pork Wontons in Chicken Soup",
  subtitle: "鸡汤馄饨",
};

export const ChickenPotstickers: MenuItemModel = {
  id: "chicken-potstickers",
  title: "Chicken Potstickers (6)",
  subtitle: "鸡肉锅贴",
};

export const PorkDumplings: MenuItemModel = {
  id: "pork-dumplings",
  title: "Pork Dumplings (10)",
  subtitle: "猪肉白菜水饺",
};

export const LambDumplings: MenuItemModel = {
  id: "lamb-dumplings",
  title: "Lamb Dumplings (10)",
  subtitle: "羊肉水饺",
};

export const ThreeFlavorDumplings: MenuItemModel = {
  id: "three-flavor-dumplings",
  title: "Shrimp, Pork, & Chive Dumplings (10)",
  subtitle: "三鲜水饺",
};

export const SpringRolls: MenuItemModel = {
  id: "spring-rolls",
  title: "Spring Rolls (2)",
  subtitle: "春卷",
};

export const HotSourRiceNoodles: MenuItemModel = {
  id: "hot-sour-rice-noodles",
  title: "Hot & Sour Rice Noodles",
  subtitle: "酸辣粉",
  spicy: true,
};

export const LambSoup: MenuItemModel = {
  id: "lamb-soup",
  title: "Lamb Soup",
  subtitle: "羊汤",
};

export const LambPaoMo: MenuItemModel = {
  id: "lamb-pao-mo",
  title: "Lamb Soup w/ Pita",
  subtitle: "羊肉泡馍",
};

export const LambHaggisSoup: MenuItemModel = {
  id: "lamb-haggis-soup",
  title: "Lamb Haggis Soup",
  subtitle: "羊杂汤",
};

export const PanFriedChickenBuns: MenuItemModel = {
  id: "pan-fried-chicken-buns",
  title: "Pan-Fried Chicken Buns (6)",
  subtitle: "生煎包",
};

export const CrabRangoons: MenuItemModel = {
  id: "crab-rangoons",
  title: "Crab Rangoons (6)",
  subtitle: "炸蟹肉盒子",
};

export const SesameNoodles: MenuItemModel = {
  id: "sesame-noodles",
  title: "Sesame Noodles",
  subtitle: "芝麻酱拌面",
};

export const GarlicChickenWings: MenuItemModel = {
  id: "garlic-chicken-wings",
  title: "Garlic Chicken Wings (4)",
  subtitle: "蒜香鸡翅",
};

// ------------------ SKEWERS ------------------

export const LambSkewers: MenuItemModel = {
  id: "lamb-skewers",
  title: "Lamb Skewers (3)",
  subtitle: "羊肉串",
};

export const BeefSkewers: MenuItemModel = {
  id: "beef-skewers",
  title: "Beef Skewers (3)",
  subtitle: "牛肉串",
};

export const FishTofuSkewers: MenuItemModel = {
  id: "fish-tofu-skewers",
  title: "Fish Tofu Skewers (3)",
  subtitle: "鱼豆腐",
};

export const ChickenWingSkewers: MenuItemModel = {
  id: "chicken-wing-skewers",
  title: "Chicken Wing Skewers (2)",
  subtitle: "烤雞全翅",
};

export const GrilledMackerelSkewer: MenuItemModel = {
  id: "grilled-mackerel-skewer",
  title: "Grilled Mackerel Skewer (1)",
  subtitle: "烤秋刀魚",
};

export const GrilledPorkBellySkewers: MenuItemModel = {
  id: "grilled-pork-belly-skewers",
  title: "Grilled Pork Belly Skewers (5)",
  subtitle: "烤五花肉",
};

export const GrilledQuailSkewers: MenuItemModel = {
  id: "grilled-quail-skewers",
  title: "Grilled Quail Skewers (2)",
  subtitle: "烤鵪鶉",
};

export const ChickenGizzardSkewers: MenuItemModel = {
  id: "chicken-gizzard-skewers",
  title: "Chicken Gizzard Skewers (3)",
  subtitle: "烤鸡胗",
};

// ------------------ EXTRAS ------------------

export const PlainBun: MenuItemModel = {
  id: "plain-bun",
  title: "Plain Bun",
  subtitle: "白膜",
};

export const WhiteRice: MenuItemModel = {
  id: "white-rice",
  title: "White Rice",
  subtitle: "米饭",
};

export const StewedPorkRice: MenuItemModel = {
  id: "stewed-pork-rice",
  title: "Stewed Pork Rice",
  subtitle: "腊汁猪肉饭",
};

export const ColdNoodles: MenuItemModel = {
  id: "cold-noodles",
  title: "Cold Noodles",
  subtitle: "凉皮",
  spicy: true,
};

// ------------------ BEVERAGES ------------------

export const BottledWater: MenuItemModel = {
  id: "bottled-water",
  title: "Bottled Water",
  subtitle: "瓶装水",
};

export const SweetTea: MenuItemModel = {
  id: "sweet-tea",
  title: "Sweet Tea",
  subtitle: "甜茶",
};

export const ArcticOceanTangerineSoda: MenuItemModel = {
  id: "arctic-ocean-soda",
  title: "Arctic Ocean Tangerine Soda",
  subtitle: "北冰洋",
};

export const FountainSoda: MenuItemModel = {
  id: "fountain-soda",
  title: "Fountain Soda",
  subtitle: "汽水",
};

export const MilkTea: MenuItemModel = {
  id: "milk-tea",
  title: "Milk Tea",
  subtitle: "奶茶",
};

export const FruitSmoothie: MenuItemModel = {
  id: "fruit-smoothie",
  title: "Fruit Smoothie",
  subtitle: "水果冰沙",
};
