import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function NavigationSection() {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 640px)` });
  const showMenu = isOpen || !isMobile;

  const isCurrentPage = (page: string): boolean => location.pathname === page;

  const genericHamburgerLine = `h-1 w-6 my-0.5 rounded-full bg-white transition ease transform duration-500`;

  return (
    <div className="flex flex-col sm:flex-row bg-primary py-2 sm:py-1 flex items-center justify-center sm:justify-start">
      <button
        className="sm:hidden my-1 flex flex-col justify-center items-center group w-full"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          className={`${genericHamburgerLine} ${
            isOpen && "rotate-45 translate-y-2"
          }`}
        />
        <div className={`${genericHamburgerLine} ${isOpen && "opacity-0"}`} />
        <div
          className={`${genericHamburgerLine} ${
            isOpen && "-rotate-45 -translate-y-2"
          }`}
        />
      </button>
      {showMenu && (
        <div className="flex flex-col sm:flex-row w-full items-center">
          <div className="sm:hidden my-2 w-5/6 bg-white h-px" />
          <Link
            className={`my-2 sm:ml-8 duration-300 hover:opacity-75 ${
              isCurrentPage("/") && "font-extrabold"
            }`}
            to="/"
          >
            HOME
          </Link>
          <Link
            className={`my-2 sm:ml-8 duration-300 hover:opacity-75 ${
              isCurrentPage("/about") && "font-extrabold"
            }`}
            to="/about"
          >
            <span>ABOUT</span>
          </Link>
          <Link
            className={`my-2 sm:ml-8 duration-300 hover:opacity-75 ${
              isCurrentPage("/careers") && "font-extrabold"
            }`}
            to="/careers"
          >
            <span>CAREERS</span>
          </Link>
          <Link
            className={`my-2 sm:ml-8 duration-300 hover:opacity-75 ${
              isCurrentPage("/contact") && "font-extrabold"
            }`}
            to="/contact"
          >
            <span>CONTACT</span>
          </Link>
          <Link
            className={`my-2 sm:ml-8 duration-300 hover:opacity-75 ${
              isCurrentPage("/gallery") && "font-extrabold"
            }`}
            to="/gallery"
          >
            <span>GALLERY</span>
          </Link>
        </div>
      )}
    </div>
  );
}
