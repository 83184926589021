import { ABOUT_PARAGRAPH_1, ABOUT_PARAGRAPH_2 } from "../data/AboutText";

export default function AboutPage() {
  return (
    <div className="text-darkText my-8 md:my-32 xl:my-48 w-screen flex items-center justify-center">
      <div className="w-5/6 sm:w-4/5 md:w-2/3 xl:w-2/5">
        <h2 className="text-primary font-bold text-4xl mb-4">ABOUT US</h2>
        <p className="mb-8">{ABOUT_PARAGRAPH_1}</p>
        <p>{ABOUT_PARAGRAPH_2}</p>
      </div>
    </div>
  );
}
