import { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";

export default function ContactPage() {
  const formRef = useRef<HTMLFormElement>(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [validForm, setValidForm] = useState(false);

  const [emailSubmitting, setEmailSubmitting] = useState(false);
  const [emailDelivered, setEmailDelivered] = useState(false);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    const validEmail = email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    setValidForm(!!name && !!validEmail && !!message);
  }, [name, email, message]);

  function clearForm() {
    setName("");
    setEmail("");
    setMessage("");
    setValidForm(false);
  }

  function submitForm(e: any) {
    e.preventDefault();

    if (!validForm) return;

    setEmailSubmitting(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID!,
        process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE_ID!,
        formRef.current as HTMLFormElement,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY!
      )
      .then(
        () => {
          setEmailDelivered(true);
        },
        () => {
          setEmailError(true);
        }
      )
      .finally(() => {
        clearForm();
        setEmailSubmitting(false);
      });
  }

  return (
    <div className="text-darkText my-8 md:my-32 xl:my-48 w-screen flex items-center justify-center">
      <div className="w-5/6 sm:w-4/5 md:w-2/3 xl:w-2/5">
        <h2 className="text-primary font-bold text-4xl mb-4">CONTACT</h2>
        <div className="rounded-md w-full">
          <div className={emailDelivered ? "" : "mb-4"}>
            {emailDelivered
              ? "Thanks for contacting us! We've received your message and will respond within 3-5 business days."
              : "We'd love to hear from you! Send us your feedback, suggestions, catering inquires, etc."}
          </div>
          {emailError && (
            <div className="mb-4 text-primary">
              There was an issue delivering your email - please try again.
            </div>
          )}
          {!emailDelivered && (
            <form ref={formRef} onSubmit={submitForm}>
              <div className="flex flex-col sm:flex-row">
                <div className="my-2 sm:w-1/2 sm:mr-2">
                  <label>Name</label>
                  <input
                    className="border border-primary rounded p-1 w-full"
                    type="text"
                    name="from_name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="my-2 sm:w-1/2 sm:ml-2">
                  <label>Email</label>
                  <input
                    className="border border-primary rounded p-1 w-full"
                    type="text"
                    name="reply_to"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label>Message</label>
                <textarea
                  className="border border-primary rounded p-1 w-full"
                  rows={5}
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <button
                className="bg-primary px-2 py-1 rounded text-lightText disabled:bg-white disabled:text-darkText duration-200"
                type="submit"
                disabled={!validForm || emailSubmitting}
              >
                SUBMIT
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
