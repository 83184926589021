import React from "react";

interface MenuSubheaderProps {
  subheader: string;
}

export default function MenuSubheader(props: MenuSubheaderProps) {
  const { subheader } = props;

  return (
    <div
      id={subheader.toLowerCase().replaceAll(" ", "-")}
      className="mx-6 md:mx-8 mb-2 text-darkText text-lg font-semibold flex items-center"
    >
      <h3 className="flex-shrink mr-4">{subheader.toUpperCase()}</h3>
      <hr className="flex-grow border-primary border" />
    </div>
  );
}
