import React, { useState } from "react";
import ReactGA from "react-ga4";
import CaretDown from "../images/caret-down.svg";
import Logo from "../images/logo-white.png";

export default function HeroSection() {
  const [toggleOpen, setToggleOpen] = useState(false);

  function handleClickMenu() {
    ReactGA.event("select_content", { content_type: "menu" });
  }

  function handleClickOrderOnline() {
    setToggleOpen(!toggleOpen);
    ReactGA.event("select_content", { content_type: "order online toggle" });
  }

  function handleClickOrderOnlineDropdown(label: string) {
    ReactGA.event("select_content", {
      content_type: "order online location dropdown",
      restaurant_location: label,
    });
  }

  function renderOption(label: string): JSX.Element {
    let url = "";
    if (label === "udistrict") {
      url = "https://www.toasttab.com/xi-an-noodles-uw-em-5259-university-way-ne";
    } else if (label === "bellevue") {
      url = "https://www.ordertogo.com/restaurants/xiannoodlesbellevue/mesh";
    } else {
      url = "https://www.ordertogo.com/restaurants/xiannoodlesseattle/mesh";
    }

    return (
      <a
        className="mx-4 w-52 py-1 bg-background text-darkText hover:brightness-90 duration-300 flex justify-center text-xl"
        href={url}
        onClick={() => handleClickOrderOnlineDropdown(label)}
        target="_blank"
        rel="noreferrer noopener"
      >
        {label.toUpperCase()}
      </a>
    );
  }

  function renderOnlineOrderToggle(): JSX.Element {
    return (
      <div onMouseLeave={() => setToggleOpen(false)}>
        <button
          className="mx-4 w-52 py-1.5 sm:py-2 bg-primary hover:brightness-90 duration-300"
          onClick={handleClickOrderOnline}
          onMouseEnter={() => setToggleOpen(true)}
        >
          <span className="flex items-center justify-center">
            <p className="mr-2 text-xl">ORDER ONLINE </p>
            <img className={`h-1.5 duration-300 ${toggleOpen && "rotate-180"}`} src={CaretDown} alt="select arrow" />
          </span>
        </button>
        <div
          className={`absolute flex flex-col divide-y duration-300 ${
            toggleOpen ? "opacity-100" : "invisible opacity-0"
          }`}
        >
          {renderOption("udistrict")}
          {renderOption("bellevue")}
          {renderOption("westlake")}
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <div className="mb-8 sm:m-8 w-full h-[80vh] md:h-[90vh] bg-hero bg-cover bg-center flex flex-col items-center justify-center">
        <img className="h-28 sm:h-36 my-4" src={Logo} alt="Xi'an Noodles logo" />
        <h1 className="text-5xl sm:text-6xl font-bold mb-4">XI'AN NOODLES</h1>
        <span className="flex flex-col sm:flex-row">
          <a
            className="hover:brightness-90 duration-300 mx-4 sm:px-4 sm:py-2 py-1.5 bg-primary flex justify-center mb-4 text-xl"
            href="#menu"
            onClick={handleClickMenu}
          >
            MENU
          </a>
          {renderOnlineOrderToggle()}
        </span>
      </div>
    </div>
  );
}
