import * as MenuItems from "./MenuItems";
import { LocationMenuItemModel } from "../models/LocationMenuItemModel";

export const WESTLAKE_MENU_ITEMS: {
  [sectionName: string]: LocationMenuItemModel[];
} = {
  Specialties: [
    {
      menuItem: MenuItems.LambNoodles,
      price: 13.99,
    },
    {
      menuItem: MenuItems.BeefNoodles,
      price: 13.99,
    },
    {
      menuItem: MenuItems.LambNoodleSoup,
      price: 14.99,
    },
    {
      menuItem: MenuItems.BeefNoodleSoup,
      price: 14.99,
    },
    {
      menuItem: MenuItems.PorkWontonsChiliSauce,
      price: 9,
    },
    {
      menuItem: MenuItems.ChickenPotstickers,
      price: 9,
    },
    {
      menuItem: MenuItems.ThreeFlavorDumplings,
      price: 12,
    },
  ],
  Starters: [
    {
      menuItem: MenuItems.CucumberSalad,
      price: 5.99,
    },
    {
      menuItem: MenuItems.BroccoliGarlicSauce,
      price: 5.99,
    },
    {
      menuItem: MenuItems.PotatoSalad,
      price: 5.99,
    },
    {
      menuItem: MenuItems.WoodEarMushrooms,
      price: 5.99,
    },
    {
      menuItem: MenuItems.MarinatedPorkIntestine,
      price: 9.99,
    },
  ],
  "Biang Biang Noodles": [
    {
      menuItem: MenuItems.LambNoodles,
      price: 13.99,
    },
    {
      menuItem: MenuItems.BeefNoodles,
      price: 13.99,
    },
    {
      menuItem: MenuItems.BraisedPorkNoodles,
      price: 13.99,
    },
    {
      menuItem: MenuItems.HotOilNoodles,
      price: 13.99,
    },
    {
      menuItem: MenuItems.TomatoEggNoodles,
      price: 13.99,
    },
    {
      menuItem: MenuItems.VegetableNoodles,
      price: 13.99,
    },
    {
      menuItem: MenuItems.QishanPorkNoodles,
      price: 13.99,
    },
  ],
  "Biang Biang Noodle Soup": [
    {
      menuItem: MenuItems.LambNoodleSoup,
      price: 14.99,
    },
    {
      menuItem: MenuItems.BeefNoodleSoup,
      price: 14.99,
    },
    {
      menuItem: MenuItems.BraisedPorkNoodleSoup,
      price: 14.99,
    },
    {
      menuItem: MenuItems.VegetableNoodleSoup,
      price: 14.99,
    },
    {
      menuItem: MenuItems.QishanPorkNoodleSoup,
      price: 14.99,
    },
  ],
  "Rice Noodle Soup": [
    {
      menuItem: MenuItems.LambRiceNoodleSoup,
      price: 13.99,
    },
    {
      menuItem: MenuItems.BeefRiceNoodleSoup,
      price: 13.99,
    },
    {
      menuItem: MenuItems.PorkIntestineRiceNoodleSoup,
      price: 13.99,
    },
    {
      menuItem: MenuItems.MushroomVegetableRiceNoodleSoup,
      price: 12.99,
    },
  ],
  "Street Food": [
    {
      menuItem: MenuItems.LambBun,
      price: 7.5,
    },
    {
      menuItem: MenuItems.BeefBun,
      price: 7.5,
    },
    {
      menuItem: MenuItems.PorkBun,
      price: 6.5,
    },
    {
      menuItem: MenuItems.PanFriedChickenBuns,
      price: 6,
    },
    {
      menuItem: MenuItems.PorkWontonsChiliSauce,
      price: 9,
    },
    {
      menuItem: MenuItems.ChickenPotstickers,
      price: 9,
    },
    {
      menuItem: MenuItems.PorkDumplings,
      price: 12,
    },
    {
      menuItem: MenuItems.LambDumplings,
      price: 13,
    },
    {
      menuItem: MenuItems.ThreeFlavorDumplings,
      price: 12,
    },
    {
      menuItem: MenuItems.SpringRolls,
      price: 6,
    },
    {
      menuItem: MenuItems.CrabRangoons,
      price: 8,
    },
    {
      menuItem: MenuItems.SesameNoodles,
      price: 11,
    },
    {
      menuItem: MenuItems.GarlicChickenWings,
      price: 8,
    },
    {
      menuItem: MenuItems.PorkWontonsChickenSoup,
      price: 7,
    },
    {
      menuItem: MenuItems.HotSourRiceNoodles,
      price: 12,
    },
    {
      menuItem: MenuItems.LambSoup,
      price: 12,
    },
    {
      menuItem: MenuItems.LambPaoMo,
      price: 15,
    },
    {
      menuItem: MenuItems.LambHaggisSoup,
      price: 16,
    },
  ],
  Extras: [
    {
      menuItem: MenuItems.PlainBun,
      price: 3,
    },
    { menuItem: MenuItems.WhiteRice, price: 3 },
    {
      menuItem: MenuItems.ColdNoodles,
      price: 9,
    },
  ],
  Beverages: [
    {
      menuItem: MenuItems.BottledWater,
      price: 1.5,
    },
    {
      menuItem: MenuItems.SweetTea,
      price: 3.5,
    },
    {
      menuItem: MenuItems.FountainSoda,
      price: 1.99,
    },
  ],
};
