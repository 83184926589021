import React from "react";

interface LocationInfoProps {
  location: string;
  address: string[];
  addressLink: string;
  hours: string | string[];
  phone: string;
  lastLocation?: boolean;
}

export default function LocationInfoBlock(props: LocationInfoProps) {
  const { location, address, addressLink, hours, phone, lastLocation } = props;

  const AddressBlock = address.map((line, idx) => {
    return (
      <p className={`${idx !== address.length - 1 && "-mb-1"}`} key={line}>
        {line}
      </p>
    );
  });

  function renderHours(hours: string | string[]): JSX.Element[] {
    const result: JSX.Element[] = [];

    if (typeof hours === "string") {
      result.push(<p className="font-bold">{hours}</p>);
    } else {
      hours.forEach((hour) => {
        result.push(<p className="font-bold">{hour}</p>);
      });
    }

    return result;
  }

  return (
    <div className={`mb-8 ${lastLocation && "sm:mb-0"}`}>
      <h3 className="text-primary text-xl font-bold">
        {location.toUpperCase()}
      </h3>
      <a
        className="duration-300 hover:text-blue-600"
        href={addressLink}
        target="_blank"
        rel="noreferrer noopener"
      >
        {AddressBlock}
      </a>
      {renderHours(hours)}
      <a
        className="text-primary duration-300 hover:text-blue-600"
        href={`tel:${phone}`}
      >
        {phone}
      </a>
    </div>
  );
}
