import React from "react";
import LocationInfoBlock from "../components/LocationInfoBlock";

import Map from "../components/Map";

export default function LocationSection() {
  return (
    <div className="sm:flex mx-6 md:mx-8 text-darkText">
      <div className="sm:mr-8 w-full sm:w-72 text-center sm:text-left">
        <LocationInfoBlock
          location="UDISTRICT"
          address={["5259 University Way NE", "Seattle, WA 98105"]}
          addressLink="https://goo.gl/maps/W8oENaNjiifoKbji6"
          hours="Sun - Sat │ 11 AM - 9 PM"
          phone="206-522-8888"
        />
        <LocationInfoBlock
          location="BELLEVUE"
          address={["1075 Bellevue Way NE B-2", "Bellevue, WA 98004"]}
          addressLink="https://goo.gl/maps/ePpByNjkwUFADf1T7"
          hours="Sun - Sat │ 11 AM - 8:30 PM"
          phone="425-286-8888"
        />
        <LocationInfoBlock
          location="WESTLAKE"
          address={[
            "Westlake Center (2nd Floor)",
            "400 Pine Street",
            "Seattle, WA 98101",
          ]}
          addressLink="https://goo.gl/maps/eSNGWZm5VseSP4SDA"
          hours={["Mon - Sat │ 11 AM - 7 PM", "Sun │ 12 PM - 7 PM"]}
          phone="206-522-9999"
        />
      </div>
      <div className="bg-lightText w-full h-72 sm:h-auto flex flex-grow">
        <Map />
      </div>
    </div>
  );
}
