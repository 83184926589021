import { useState } from "react";
import MenuHeader from "../components/MenuHeader";
import MenuItem from "../components/MenuItem";
import MenuItemPlaceholder from "../components/MenuItemPlaceholder";
import MenuSubheader from "../components/MenuSubheader";
import { BELLEVUE_MENU_ITEMS } from "../data/BellevueMenu";
import { UDISTRICT_MENU_ITEMS } from "../data/UDistrictMenu";
import { WESTLAKE_MENU_ITEMS } from "../data/WestlakeMenu";
import { LocationMenuItemModel } from "../models/LocationMenuItemModel";

export default function MenuSection() {
  const [location, setLocation] = useState("udistrict");

  function renderMenuItems(): JSX.Element[] {
    var result: JSX.Element[] = [];

    var menu: {
      [sectionName: string]: LocationMenuItemModel[];
    };
    switch (location) {
      case "udistrict":
        menu = UDISTRICT_MENU_ITEMS;
        break;
      case "bellevue":
        menu = BELLEVUE_MENU_ITEMS;
        break;
      case "westlake":
        menu = WESTLAKE_MENU_ITEMS;
        break;
      default:
        menu = UDISTRICT_MENU_ITEMS;
    }

    const sections = Object.keys(menu);
    sections.forEach((section) => {
      result.push(<MenuSubheader subheader={section} key={section} />);
      const menuItems = menu[section];
      for (let i = 0; i < menuItems.length; i += 2) {
        result.push(
          <div
            className="md:flex"
            key={`${section}-${menuItems[i].menuItem.id}`}
          >
            {<MenuItem locationMenuItem={menuItems[i]} />}
            {i === menuItems.length - 1 ? (
              <MenuItemPlaceholder />
            ) : (
              <MenuItem locationMenuItem={menuItems[i + 1]} />
            )}
          </div>
        );
      }
    });
    return result;
  }

  return (
    <div className="my-8">
      <MenuHeader location={location} setLocation={setLocation} />
      {renderMenuItems()}
    </div>
  );
}
