import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider, { Settings } from "react-slick";
import ReactGA from "react-ga4";
import TheStranger from "../images/featured_logos/the-stranger.png";
import CondeNast from "../images/featured_logos/conde-nast.png";
import Eater from "../images/featured_logos/eater.png";
import SeattleMet from "../images/featured_logos/seattle-met.png";
import RoadFood from "../images/featured_logos/roadfood.png";
import SeattleTimes from "../images/featured_logos/the-seattle-times.png";
import Infatuation from "../images/featured_logos/infatuation.png";
import FamilyDestinationsGuide from "../images/featured_logos/family-destinations-guide.png";

const THE_STRANGER_URL =
  "https://www.thestranger.com/food-and-drink/2017/12/20/25633193/the-delicious-hand-ripped-noodles-of-xian";
const CONDE_NAST_URL =
  "https://www.cntraveler.com/gallery/best-restaurants-in-seattle";
const SEATTLE_TIMES_URL =
  "https://www.seattletimes.com/life/food-drink/xian-noodles-some-of-the-very-best/";
const ROADFOOD_URL = "https://roadfood.com/restaurants/xian-noodles/";
const SEATTLE_MET_URL = "https://www.seattlemet.com/restaurants/xi-an-noodles";
const EATER_URL =
  "https://seattle.eater.com/2016/4/29/11538966/xian-noodles-open-biang-biang-chinese";
const INFATUATION_URL =
  "https://www.theinfatuation.com/seattle/reviews/xian-noodles";
const FAMILY_DESTINATIONS_GUIDE_URL =
  "https://familydestinationsguide.com/best-lunch-in-seattle-wa/";

export default function FeaturedInSection() {
  const settings: Settings = {
    infinite: true,
    speed: 2000,
    autoplaySpeed: 2000,
    arrows: false,
    variableWidth: true,
    autoplay: true,
    centerMode: true,
  };

  function handleClickFeaturedLogo(company: string) {
    ReactGA.event("select_content", {
      content_type: "featured in logo",
      value: company,
    });
  }

  function renderFeaturedLogo(src: string, url: string, alt: string) {
    return (
      <div className="mx-8">
        <a
          href={url}
          target="_blank"
          rel="noreferrer noopener"
          onClick={() => handleClickFeaturedLogo(alt)}
        >
          <img className="h-12" src={src} alt={alt} />
        </a>
      </div>
    );
  }

  return (
    <div className="bg-primary py-8 mb-8">
      <div className="text-lg w-full mb-8 flex items-center justify-center">
        <span className="w-1/4 h-[.5px] bg-lightText" />
        <h3 className="mx-4">FEATURED IN</h3>
        <span className="w-1/4 h-[.5px] bg-lightText" />
      </div>
      <Slider {...settings}>
        {renderFeaturedLogo(CondeNast, CONDE_NAST_URL, "Conde Nast logo")}
        {renderFeaturedLogo(TheStranger, THE_STRANGER_URL, "The Stranger logo")}
        {renderFeaturedLogo(
          SeattleTimes,
          SEATTLE_TIMES_URL,
          "Seattle Times logo"
        )}
        {renderFeaturedLogo(Eater, EATER_URL, "Eater Logo")}
        {renderFeaturedLogo(RoadFood, ROADFOOD_URL, "RoadFood logo")}
        {renderFeaturedLogo(SeattleMet, SEATTLE_MET_URL, "Seattle Met Logo")}
        {renderFeaturedLogo(
          Infatuation,
          INFATUATION_URL,
          "The Infatuation Logo"
        )}
        {renderFeaturedLogo(
          FamilyDestinationsGuide,
          FAMILY_DESTINATIONS_GUIDE_URL,
          "Family Destinations Guide Logo"
        )}
      </Slider>
    </div>
  );
}
