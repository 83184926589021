import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import BannerSection from "./sections/BannerSection";
import NavigationSection from "./sections/NavigationSection";
import FooterSection from "./sections/FooterSection";
import ContactPage from "./pages/ContactPage";
import GalleryPage from "./pages/GalleryPage";
import CareersPage from "./pages/CareersPage";
import AnnouncementModal from "./components/AnnoucementModal";
import { useState } from "react";

function App() {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID!);
  ReactGA.send("pageview");

  const showAnnouncementModal = false;
  const showNotificationBanner = false;
  const [announcementModalOpen, setAnnouncementModalOpen] = useState(true);

  return (
    <Router>
      <div className="text-lightText bg-background max-w-[4000px] min-w-[350px] m-auto">
        {showAnnouncementModal && announcementModalOpen && <AnnouncementModal setOpen={setAnnouncementModalOpen} />}
        {showNotificationBanner && <BannerSection setAnnouncementModalOpen={setAnnouncementModalOpen} />}
        <NavigationSection />
        <Routes>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
        <FooterSection />
      </div>
    </Router>
  );
}

export default App;
