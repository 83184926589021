import React, { useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

const mapStyle = {
  width: "100%",
  height: "100%",
};

const UDISTRICT_LOCATION = { lat: 47.668244329816, lng: -122.31324722806228 };
const BELLEVUE_LOCATION = { lat: 47.61980139664651, lng: -122.20263432806321 };
const WESTLAKE_LOCATION = { lat: 47.61237902986933, lng: -122.33729965875041 };

export default function Map() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });

  const center = useMemo(
    () => ({ lat: 47.644274014327725, lng: -122.2615281059311 }),
    []
  );
  const zoom = useMemo(() => 11, []);

  if (!isLoaded) return <p>Loading Map...</p>;

  return (
    <GoogleMap
      mapContainerStyle={mapStyle}
      zoom={zoom}
      center={center}
      options={{ mapTypeControl: false, streetViewControl: false }}
    >
      <Marker position={UDISTRICT_LOCATION} />
      <Marker position={BELLEVUE_LOCATION} />
      <Marker position={WESTLAKE_LOCATION} />
    </GoogleMap>
  );
}
