import { ImageList } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import GalleryImage from "../components/GalleryImage";

export default function GalleryPage() {
  const isMobile = useMediaQuery({ query: `(max-width: 640px)` });

  function importAll(r: __WebpackModuleApi.RequireContext) {
    return r.keys().map(r);
  }
  const images = importAll(
    require.context("../images/gallery", false, /\.(jpg)$/)
  );

  return (
    <div className="m-8 lg:m-32 xl:m-48 items-center text-darkText">
      <h2 className="text-primary font-bold text-4xl mb-4">GALLERY</h2>
      <ImageList cols={isMobile ? 2 : 4}>
        {images.map((image) => (
          <GalleryImage image={image as string} key={image as string} />
        ))}
      </ImageList>
    </div>
  );
}
